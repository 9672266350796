import React, { useEffect, useState } from 'react';
import { Button } from '@rescui/button';
import PropTypes from 'prop-types';
import { ShareIcon, CopyIcon } from '@rescui/icons';
import { linkToPath, useViewport } from '../services/helper';
import { Tooltip } from '@rescui/tooltip';

const PluginLinkButton = ({ plugin, size }) => {
  const link = linkToPath(`/p/${plugin.id}`);
  return (
    <>
      <CopyButton link={link} size={size} />
      <ShareButton data={{ title: `Ktor - ${plugin.name}`, url: link }} size={size} />
    </>
  );
};

PluginLinkButton.propTypes = {
  plugin: PropTypes.object,
  size: PropTypes.string,
};

const ShareButton = ({ data, size }) => {
  if (!navigator.canShare || !navigator.canShare(data)) {
    return [];
  }
  return (
    <Button
      mode="clear"
      icon={<ShareIcon />}
      size={size || 'xs'}
      onClick={() => {
        navigator.share(data).then(() => {
          // TODO analytics
        }).catch((error) => {
          console.error('Error sharing', error);
        });
      }}
    >
    Share
    </Button>);
};

ShareButton.propTypes = {
  data: PropTypes.object,
  size: PropTypes.string,
};

const CopyButton = ({ link, size }) => {
  const { width } = useViewport();
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  useEffect(() => {
    if (showCopiedMessage) {
      setTimeout(() => setShowCopiedMessage(false), 1_000);
    }
  }, [showCopiedMessage, setShowCopiedMessage]);

  if (!navigator.clipboard || width < 590) {
    return [];
  }

  return (
    <Tooltip content="Copied link to plugin!" isVisible={showCopiedMessage}>
      <Button
        mode="clear"
        icon={<CopyIcon />}
        size={size || 'xs'}
        onClick={() => {
          navigator.clipboard.writeText(link).then(() => {
            setShowCopiedMessage(true);
          }).catch((error) => {
            console.error('Error copying to clipboard', error);
          });
        }}
      >
        Copy Link
      </Button>
    </Tooltip>
  );
};

CopyButton.propTypes = {
  link: PropTypes.string,
  size: PropTypes.string,
};

export default PluginLinkButton;
